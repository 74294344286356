<template>
  <div class="content" ref="ss"></div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    this.initSpread()
  },
  methods: {
    initSpread() {
      var spread = new GC.Spread.Sheets.Workbook(this.$refs.ss, { sheetCount: 1 })
      var sheet = spread.getSheet(0)
      var person = { name: 'Wang feng', age: 25, gender: 'Male', address: { postcode: '710075' }}
      var source = new GC.Spread.Sheets.Bindings.CellBindingSource(person)
      sheet.setBindingPath(2, 2, 'name')
      sheet.setBindingPath(3, 2, 'age')
      sheet.setBindingPath(4, 2, 'gender')
      sheet.setBindingPath(5, 2, 'address.postcode')
      sheet.setDataSource(source)
    }
  }
}
</script>
<style>
.content {
  width: 100%;
  height: 100%;
}
</style>
